var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.organisation-add-invite",
              modifiers: { "organisation-add-invite": true },
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "organisation-add-invite",
            "sidebar-class": "small_sidebar",
            right: "",
            title: _vm.$t("ORGANISATION.ADD_USER"),
            backdrop: "",
            lazy: "",
            "no-slide": "",
            shadow: "",
          },
          on: { shown: _vm.showSidebar },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
            _c(
              "div",
              { staticClass: "px-3 py-2 pt-0 create-message" },
              [
                _c("h5", { staticClass: "mt-3" }, [
                  _vm._v(_vm._s(_vm.$t("EMAIL"))),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        trim: "",
                        required: "",
                        type: "text",
                        name: "email",
                      },
                      model: {
                        value: _vm.payload.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.payload, "email", $$v)
                        },
                        expression: "payload.email",
                      },
                    }),
                  ],
                  1
                ),
                _c("h5", { staticClass: "mt-3" }, [
                  _vm._v(_vm._s(_vm.$t("NAME"))),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        trim: "",
                        required: "",
                        type: "text",
                        name: "name",
                      },
                      model: {
                        value: _vm.payload.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.payload, "name", $$v)
                        },
                        expression: "payload.name",
                      },
                    }),
                  ],
                  1
                ),
                _c("h5", { staticClass: "mt-3" }, [
                  _vm._v(_vm._s(_vm.$t("TITLE"))),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        trim: "",
                        required: "",
                        type: "text",
                        name: "name",
                      },
                      model: {
                        value: _vm.payload.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.payload, "title", $$v)
                        },
                        expression: "payload.title",
                      },
                    }),
                  ],
                  1
                ),
                _c("h5", { staticClass: "mt-3" }, [
                  _vm._v(_vm._s(_vm.$t("DEPARTMENT"))),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        trim: "",
                        required: "",
                        type: "text",
                        name: "name",
                      },
                      model: {
                        value: _vm.payload.department,
                        callback: function ($$v) {
                          _vm.$set(_vm.payload, "department", $$v)
                        },
                        expression: "payload.department",
                      },
                    }),
                  ],
                  1
                ),
                _c("h5", { staticClass: "mt-3" }, [
                  _vm._v(_vm._s(_vm.$t("ADMINLEVEL.TITLE"))),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c("b-form-select", {
                      attrs: { options: _vm.adminOptions },
                      model: {
                        value: _vm.payload.is_admin,
                        callback: function ($$v) {
                          _vm.$set(_vm.payload, "is_admin", $$v)
                        },
                        expression: "payload.is_admin",
                      },
                    }),
                  ],
                  1
                ),
                _c("h5", { staticClass: "mt-3" }, [
                  _vm._v(_vm._s(_vm.$t("LANGUAGE.TITLE"))),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c(
                      "b-form-select",
                      {
                        attrs: { t: "", name: "add_invite_select_language" },
                        model: {
                          value: _vm.payload.language,
                          callback: function ($$v) {
                            _vm.$set(_vm.payload, "language", $$v)
                          },
                          expression: "payload.language",
                        },
                      },
                      _vm._l(_vm.sefos_locales, function (value, key) {
                        return _c(
                          "b-form-select-option",
                          { key: key, attrs: { value: key } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("LANGUAGE." + key)) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("h5", { staticClass: "mt-3" }, [
                  _vm._v(_vm._s(_vm.$t("AUTH_METHODS.SELECT_METHOD"))),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c("b-form-select", {
                      staticClass: "mb-2",
                      attrs: { options: _vm.options },
                      on: { input: _vm.changedAuth },
                      model: {
                        value: _vm.selectedIndex,
                        callback: function ($$v) {
                          _vm.selectedIndex = $$v
                        },
                        expression: "selectedIndex",
                      },
                    }),
                    _vm.payload.auth_type == "se-eid"
                      ? _c("Pnr", {
                          attrs: { required: "" },
                          on: { valid: _vm.setValidated },
                          model: {
                            value: _vm.payload.data.pnr,
                            callback: function ($$v) {
                              _vm.$set(_vm.payload.data, "pnr", $$v)
                            },
                            expression: "payload.data.pnr",
                          },
                        })
                      : _vm._e(),
                    _vm.payload.auth_type == "password-sms"
                      ? _c("Phone", {
                          attrs: { required: "" },
                          on: { valid: _vm.setValidated },
                          model: {
                            value: _vm.payload.data.identifier,
                            callback: function ($$v) {
                              _vm.$set(_vm.payload.data, "identifier", $$v)
                            },
                            expression: "payload.data.identifier",
                          },
                        })
                      : _vm._e(),
                    _vm.showIdentifier
                      ? _c("Identifier", {
                          attrs: { placeholder: _vm.$t("UNIQUE_IDENTIFIER") },
                          model: {
                            value: _vm.payload.data.identifier,
                            callback: function ($$v) {
                              _vm.$set(_vm.payload.data, "identifier", $$v)
                            },
                            expression: "payload.data.identifier",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-fill",
                    attrs: {
                      disabled: !_vm.canCreateInvite,
                      variant: "primary",
                    },
                    on: { click: _vm.addInvite },
                  },
                  [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }